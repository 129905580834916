import { SearchInput } from 'components/Forms/TextInput/SearchInput';
import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

type AutocompleteSearchInnerStyledProps = {
    isActive: boolean;
};

export const AutocompleteSearchStyled = styled.div(
    ({ theme }) => css`
        height: 48px;

        @media ${theme.mediaQueries.queryLg} {
            position: relative;
        }
    `,
);

export const AutocompleteSearchInStyled = styled.div(
    ({ theme }) => css`
        transition: all 0.2s cubic-bezier(0.8, 0.2, 0.48, 1);

        @media ${theme.mediaQueries.queryLg} {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: ${theme.zIndex.aboveMenu};
        }
    `,
);

export const AutocompleteSearchInnerStyled = styled.div<AutocompleteSearchInnerStyledProps>(
    ({ theme, isActive }) => css`
        position: relative;
        display: flex;
        transition: all ${theme.transition};
        width: 100%;

        ${isActive &&
        css`
            z-index: ${theme.zIndex.aboveMenu + 1};

            input:not(input:placeholder-shown) {
                border: 2px solid ${theme.color.primary};
            }
        `};

        & input {
            border: 2px solid ${theme.color.white};
            border-radius: ${theme.radius.big};
        }

        @media ${theme.mediaQueries.queryLg} {
            ${isActive &&
            css`
                width: 576px;
            `}

            &:focus-within {
                width: 576px;
            }
        }

        @media ${theme.mediaQueries.queryNotLargeDesktop} {
            & input {
                ${isActive &&
                css`
                    width: 100%;
                    border-color: ${theme.color.primaryLight};
                `}
            }
        }
    `,
);

export const AutocompleteSearchRemoveButtonStyled = styled.div(
    ({ theme }) => css`
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: all ${theme.transition};

        background-color: ${theme.color.greyVeryLight};
        border-radius: 50%;
        cursor: pointer;

        i {
            width: 10px;
            height: 10px;
        }

        @media ${theme.mediaQueries.queryTablet} {
            position: relative;
            padding: 0 10px;

            background-color: ${theme.color.orangeLight};
            border-radius: ${theme.radius.big};
        }
    `,
);

export const AutocompleteSearchInputStyled = styled(SearchInput)`
    .search-input-enter {
        transform: translate(-100px);
    }
    .search-input-enter-active {
        transform: translate(-100px);
    }
`;
