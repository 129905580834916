import {
    DropdownHamburgerMenuStyled,
    DropdownHeaderStyled,
    DropdownMenuListStyled,
    DropdownMenuStyled,
    DropdownMenuWrapperStyled,
} from './DropdownMenu.style';
import { DropdownMenuContext } from './DropdownMenuContext';
import { PrimaryList } from './PrimaryList/PrimaryList';
import { SecondaryList } from './SecondaryList/SecondaryList';
import { DropdownSlideLeft } from './SlideLeft/DropdownSlideLeft';
import { SubMenu } from './SubMenu/SubMenu';
import { TertiaryList } from './TertiaryList/TertiaryList';
import { useNavigationItems } from 'connectors/navigation/Navigation';
import { forwardRef, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { DropdownItemType, DropdownListLevels } from 'types/dropdown';

type DropdownMenuProps = {
    isMenuOpened: boolean;
    onMenuToggleHandler: () => void;
};

const TEST_IDENTIFIER = 'layout-header-dropdownmenu';

export const DropdownMenu = forwardRef<HTMLDivElement, DropdownMenuProps>(
    ({ isMenuOpened, onMenuToggleHandler }, dropdownRef) => {
        const navigationItems = useNavigationItems();
        const [menuLevel, setMenuLevel] = useState<DropdownListLevels | undefined>('primary');
        const [historyOfIndexes, setHistoryOfIndexes] = useState<(number | string | undefined)[]>([]);
        const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');
        const [menuHeight, setMenuHeight] = useState<number>();
        const dropdownHeaderRef = useRef<HTMLDivElement>(null);
        const dropdownListRef = useRef<HTMLDivElement>(null);

        if (navigationItems.length === 0) {
            return null;
        }

        const calcHeight = () => {
            if (dropdownListRef.current && dropdownHeaderRef.current) {
                setMenuHeight(dropdownListRef.current.offsetHeight + dropdownHeaderRef.current.offsetHeight);
            }
        };

        const slideLeft = (props: { goToMenu: DropdownListLevels }) => {
            setMenuLevel(props.goToMenu);
            setSlideDirection('left');

            historyOfIndexes.pop();

            if (historyOfIndexes.length === 0) {
                setHistoryOfIndexes([]);
            } else {
                setHistoryOfIndexes([...historyOfIndexes]);
            }
        };

        const slideRight = (props: DropdownItemType) => {
            setMenuLevel(props.goToMenu);
            setSlideDirection('right');
            setHistoryOfIndexes((oldArray: (number | string | undefined)[]) => [...oldArray, props.index]);
        };

        return (
            <DropdownMenuWrapperStyled data-testid={TEST_IDENTIFIER} ref={dropdownRef}>
                <CSSTransition
                    in={isMenuOpened}
                    timeout={500}
                    classNames="dropdown"
                    onEntering={calcHeight}
                    unmountOnExit
                >
                    <DropdownMenuContext.Provider value={{ slideRight, onMenuToggleHandler }}>
                        <DropdownMenuStyled slideDirection={slideDirection} style={{ height: menuHeight }}>
                            <DropdownHeaderStyled ref={dropdownHeaderRef}>
                                {menuLevel !== 'primary' && (
                                    <DropdownSlideLeft
                                        onClickEvent={slideLeft}
                                        goToMenu={menuLevel === 'secondary' ? 'primary' : 'secondary'}
                                    />
                                )}
                                <DropdownHamburgerMenuStyled isMenuOpened onMenuToggleHandler={onMenuToggleHandler} />
                            </DropdownHeaderStyled>
                            <CSSTransition
                                in={menuLevel === 'primary'}
                                timeout={500}
                                classNames="menu-primary"
                                unmountOnExit
                                onEntering={calcHeight}
                            >
                                <DropdownMenuListStyled ref={dropdownListRef} menuLevel="primary">
                                    <PrimaryList navigationItems={navigationItems} />
                                    <SubMenu />
                                </DropdownMenuListStyled>
                            </CSSTransition>

                            <CSSTransition
                                in={menuLevel === 'secondary'}
                                timeout={500}
                                classNames="menu-secondary"
                                unmountOnExit
                                onEntering={calcHeight}
                            >
                                <DropdownMenuListStyled ref={dropdownListRef}>
                                    <SecondaryList
                                        navigationItems={navigationItems}
                                        historyOfIndexes={historyOfIndexes}
                                    />
                                </DropdownMenuListStyled>
                            </CSSTransition>

                            <CSSTransition
                                in={menuLevel === 'tertiary'}
                                timeout={500}
                                classNames="menu-tertiary"
                                unmountOnExit
                                onEntering={calcHeight}
                            >
                                <DropdownMenuListStyled ref={dropdownListRef}>
                                    <TertiaryList
                                        navigationItems={navigationItems}
                                        historyOfIndexes={historyOfIndexes}
                                    />
                                </DropdownMenuListStyled>
                            </CSSTransition>
                        </DropdownMenuStyled>
                    </DropdownMenuContext.Provider>
                </CSSTransition>
            </DropdownMenuWrapperStyled>
        );
    },
);

DropdownMenu.displayName = 'DropdownMenu';
