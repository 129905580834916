import { HamburgerMenu } from '../HamburgerMenu/HamburgerMenu';
import { styled } from 'components/Theme/main';
import { HTMLAttributes } from 'react';
import { css } from 'styled-components';
import { DropdownListLevels } from 'types/dropdown';

type DropdownMenuStyledProps = HTMLAttributes<HTMLDivElement> & {
    slideDirection: 'left' | 'right';
};

const localVariables = {
    heightOfDropdownMenuHeader: '66px',
};

export const DropdownMenuWrapperStyled = styled.div`
    .dropdown-enter {
        transform: translateY(-110%);
    }
    .dropdown-enter-active {
        transform: translateY(0);
        transition: all 0.5s ease;
    }
    .dropdown-exit {
        transform: translateY(0);
    }
    .dropdown-exit-active {
        transform: translateY(-110%);
        transition: all 0.5s ease;
    }
`;

export const DropdownMenuStyled = styled.div<DropdownMenuStyledProps>(
    ({ theme, slideDirection }) => css`
        position: fixed;
        top: 0;
        z-index: ${theme.zIndex.mobileMenu};
        overflow: scroll;
        cursor: auto;
        inset: 0 10px 10px;
        max-height: 99vh;

        background-color: ${theme.color.white};
        box-shadow: 0 5px 10px 0 rgba(164, 167, 193, 0.34);
        transition: height 0.3s ease;

        .menu-primary-enter {
            position: absolute;
            transform: translateX(-110%);
        }

        .menu-primary-enter-active {
            transform: translateX(0%);
            transition: all 0.3s ease;
        }

        .menu-primary-exit {
            position: absolute;
        }

        .menu-primary-exit-active {
            transform: translateX(-110%);
            transition: all 0.3s ease;
        }

        .menu-secondary-enter {
            ${slideDirection === 'right'
                ? css`
                      transform: translateX(110%);
                  `
                : css`
                      transform: translateX(-110%);
                      position: absolute;
                  `}
        }

        .menu-secondary-enter-active {
            ${slideDirection === 'right'
                ? css`
                      transform: translateX(0%);
                  `
                : css`
                      transform: translateX(0%);
                  `}
            transition: all 0.3s ease;
        }

        .menu-secondary-exit {
            ${slideDirection === 'right' &&
            css`
                position: absolute;
                transform: translateX(0%);
            `}
        }

        .menu-secondary-exit-active {
            ${slideDirection === 'right'
                ? css`
                      transform: translateX(-110%);
                  `
                : css`
                      transform: translateX(110%);
                  `}
            transition: all 0.3s ease;
        }

        .menu-tertiary-enter {
            ${slideDirection === 'right'
                ? css`
                      transform: translateX(110%);
                  `
                : css`
                      transform: translateX(0%);
                  `}
        }
        .menu-tertiary-enter-active {
            ${slideDirection === 'right'
                ? css`
                      transform: translateX(0%);
                  `
                : css`
                      transform: translateX(110%);
                  `}
            transition: all 0.3s ease;
        }
        .menu-tertiary-exit {
            ${slideDirection === 'left' &&
            css`
                transform: translateX(0%);
            `}
        }
        .menu-tertiary-exit-active {
            transform: translateX(110%);
            transition: all 0.3s ease;
        }
    `,
);

type DropdownMenuListStyledProps = HTMLAttributes<HTMLDivElement> & {
    menuLevel?: DropdownListLevels;
};

export const DropdownMenuListStyled = styled.div<DropdownMenuListStyledProps>(
    ({ menuLevel }) => css`
        height: ${menuLevel === 'primary'
            ? 'fit-content'
            : `calc(99vh - ${localVariables.heightOfDropdownMenuHeader})`};
        width: 100%;
    `,
);

export const DropdownHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 10px 18px 30px;
`;

export const DropdownHamburgerMenuStyled = styled(HamburgerMenu)`
    margin-left: auto;
`;
