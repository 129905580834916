import { styled } from 'components/Theme/main';
import { HTMLAttributes } from 'react';
import { css } from 'styled-components';

type DropdownItemStyledProps = HTMLAttributes<HTMLDivElement> & {
    variant?: 'primary' | 'secondary' | 'tertiary';
};

type DropdownItemLinkStyledProps = HTMLAttributes<HTMLAnchorElement> & {
    variant?: 'primary' | 'secondary' | 'tertiary';
};

export const DropdownItemStyled = styled.div<DropdownItemStyledProps>(
    ({ theme, variant }) => css`
        display: flex;
        ${variant !== 'primary' && 'margin: 0 30px'};

        border-bottom: 1px solid ${theme.color.greyLighter};

        &:last-child {
            border-bottom: 0;
        }
    `,
);

export const DropdownItemLinkStyled = styled.a<DropdownItemLinkStyledProps>(
    ({ theme, variant }) => css`
        padding: ${variant !== 'primary' ? '15px 0 14px 0' : '20px 45px 18px 30px'};
        flex: 1;

        font-size: ${variant === 'primary' ? `${theme.fontSize.small}` : `${theme.fontSize.default}`};
        font-weight: 700;
        text-transform: ${variant === 'tertiary' ? 'none' : 'uppercase'};
        text-decoration: none;
        color: ${variant === 'primary' && theme.color.primary};
    `,
);
