import * as React from 'react';

export const Arrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M509.498 163.448l-53.446-53.445L256 310.054 55.95 110.003 2.503 163.448l226.775 226.775a37.8 37.8-90 0 0 53.445 0z"
            fill="currentColor"
        />
    </svg>
);

export const ArrowRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M501.396 236.195l-183.095-183.1C313.074 47.87 306.108 45 298.68 45c-7.437 0-14.399 2.873-19.625 8.095l-16.624 16.628c-5.222 5.219-8.1 12.189-8.1 19.62 0 7.428 2.878 14.633 8.1 19.852l106.816 107.05H29.89c-15.3 0-27.39 11.978-27.39 27.283v23.507c0 15.305 12.09 28.491 27.39 28.491h340.57L262.435 403.174c-5.222 5.227-8.1 12.007-8.1 19.439 0 7.423 2.878 14.303 8.1 19.525l16.624 16.575c5.227 5.226 12.189 8.075 19.624 8.075 7.428 0 14.394-2.886 19.62-8.112L501.4 275.58c5.24-5.243 8.12-12.242 8.1-19.682.016-7.465-2.86-14.468-8.104-19.703z"
            fill="currentColor"
        />
    </svg>
);

export const Cart = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M117.806 2.5c-9.153 0-16.084 4.686-20.256 10.154C80.848 34.54 46.937 80.24 29.052 103.936c-.747.99-5.002 6.232-5.003 15.155L24 438.723c-.008 39.21 31.737 70.777 71.158 70.777h319.09c39.422 0 71.147-31.567 71.159-70.777l.097-319.583c.002-8.003-3.81-13.564-5.052-15.204-18.224-24.073-49.903-67.235-68.448-91.282C405.367 4.048 397.895 2.5 391.698 2.5zm12.68 50.718h248.532l30.469 40.533H99.896zm-55.938 90.643h360.068l.073 293.791c.003 13.95-10.04 21.378-20.257 21.378H94.926c-9.308 0-20.302-6.452-20.306-21.378zm63.392 53.3v25.357c0 64.123 52.517 116.69 116.64 116.69 64.123 0 116.638-52.567 116.638-116.69V197.16h-50.716v25.358c0 36.714-29.208 65.971-65.922 65.971-36.713 0-65.922-29.257-65.922-65.97V197.16z"
            fill="currentColor"
        />
    </svg>
);

export const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M509.5 256c0 140.004-113.496 253.5-253.5 253.5C115.996 509.5 2.5 396.004 2.5 256 2.5 115.996 115.996 2.5 256 2.5c140.004 0 253.5 113.496 253.5 253.5z"
            overflow="visible"
            fill="currentColor"
        />
        <path
            d="M163.248 123.419l-39.83 39.829L216.172 256l-92.752 92.752 39.829 39.83L256 295.828l92.752 92.752 39.83-39.829L295.828 256l92.752-92.752-39.829-39.83L256 216.172z"
            color="#000"
            overflow="visible"
            fill="#fff"
        />
    </svg>
);

export const Search = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M225.772 2.5C102.663 2.5 2.5 102.663 2.5 225.772c0 123.116 100.163 223.272 223.272 223.272 123.116 0 223.272-100.156 223.272-223.272C449.044 102.663 348.888 2.5 225.772 2.5zm0 405.326c-100.383 0-182.053-81.67-182.053-182.053S125.39 43.719 225.772 43.719s182.053 81.67 182.053 182.053-81.67 182.054-182.053 182.054z"
            fill="currentColor"
        />
        <path
            d="M503.461 474.319L385.3 356.156c-8.052-8.051-21.091-8.051-29.143 0-8.051 8.045-8.051 21.098 0 29.143L474.32 503.46a20.538 20.538 0 0 0 14.571 6.039 20.551 20.551 0 0 0 14.571-6.039c8.052-8.044 8.052-21.098 0-29.142z"
            fill="currentColor"
        />
    </svg>
);

export const Chat = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M244.208.496c37.877-.09 75.223 8.779 109.04 25.84 82.575 41.269 134.844 125.767 134.88 218.08.078 32.778-9.529 64.314-22.366 94.358l46.52 139.615a26.947 26.947 0 01-34.1 34.102L338.564 465.97c-30.059 12.844-61.614 22.451-94.41 22.366-92.253-.057-176.694-52.277-217.975-134.775v-.053C9.092 319.66.19 282.278.288 244.362v-12.734a26.947 26.947 0 01.054-1.474C7.163 106.498 106.29 7.372 229.946.55a26.947 26.947 0 011.474-.053h12.735zm.052 53.889a26.947 26.947 0 01-.052 0h-11.473C136.24 59.82 59.613 136.448 54.177 232.944v11.472a26.947 26.947 0 010 .052 186.993 186.993 0 0020.103 84.78 26.947 26.947 0 01.053.105c32.196 64.422 97.855 105.066 169.875 105.094a26.947 26.947 0 01.052 0 186.978 186.978 0 0084.78-20.103 26.947 26.947 0 0120.682-1.473l94.358 31.417-31.418-94.358a26.947 26.947 0 011.474-20.682 186.977 186.977 0 0020.103-84.78 26.947 26.947 0 010-.052c-.028-72.02-40.672-137.679-105.094-169.876a26.947 26.947 0 01-.105-.052 186.982 186.982 0 00-84.78-20.103z"
            overflow="visible"
            fill="currentColor"
        />
    </svg>
);

export const Marker = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M256.01.321C138.305.321 42.711 96.981 44 214.658c.062 83.38 51.484 155.077 100.261 206.563 48.807 51.518 97.687 84.022 97.687 84.022a25.353 25.353 0 0028.123 0s48.886-32.504 97.686-84.022c48.771-51.487 100.2-123.185 100.262-206.563C469.307 96.98 373.713.32 256.009.32zm0 50.7c90.145 0 162.36 73.052 161.309 163.191a25.353 25.353 0 000 .297c0 61.712-41.823 124.801-86.349 171.806-37.49 39.579-64.064 56.968-74.96 64.613-10.901-7.647-37.418-25.037-74.912-64.613C136.566 339.31 94.7 276.22 94.7 214.51a25.353 25.353 0 000-.297c-1.05-90.139 71.164-163.19 161.31-163.19z"
            overflow="visible"
            fill="currentColor"
        />

        <path
            d="M256.01 126.923c-48.072 0-87.587 39.515-87.587 87.586 0 48.072 39.515 87.587 87.586 87.587 48.072 0 87.587-39.515 87.587-87.587 0-48.07-39.515-87.586-87.587-87.586zm0 50.7c20.67 0 36.886 16.216 36.886 36.886 0 20.671-16.216 36.887-36.887 36.887-20.67 0-36.886-16.216-36.886-36.887 0-20.67 16.216-36.886 36.886-36.886z"
            overflow="visible"
            fill="currentColor"
        />
    </svg>
);

export const User = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M255.992 315.584c-59.93 0-111.747 14.714-152.185 45.576C63.368 392.022 35.587 438.08 20 495.766l50.822 13.732c13.368-49.474 35.166-83.777 64.924-106.488 29.758-22.711 68.752-34.781 120.246-34.781 51.492 0 90.487 12.074 120.246 34.789 29.76 22.714 51.558 57.021 64.926 106.482l50.822-13.736C476.4 438.092 448.62 392.035 408.182 361.17c-40.437-30.865-92.257-45.586-152.19-45.586zm0-313.084c-72.376 0-131.613 59.237-131.613 131.613 0 72.377 59.237 131.616 131.613 131.616 72.377 0 131.615-59.24 131.615-131.616S328.37 2.5 255.992 2.5zm0 52.646c43.925 0 78.969 35.043 78.969 78.967 0 43.925-35.044 78.969-78.969 78.969s-78.967-35.044-78.967-78.969c0-43.924 35.042-78.967 78.967-78.967z"
            overflow="visible"
            fill="currentColor"
        />
    </svg>
);

export const Close = () => (
    <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 1L1 11" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
        <path d="M1 1L11 11" stroke="currentColor" stroke-width="2" stroke-linejoin="round" />
    </svg>
);

export const Menu = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12">
        <g fill="none">
            <path stroke="currentColor" d="M0 6H15.996 0" />
            <path fill="currentColor" d="M16 0v2H0V0zM15.996 10v2H0v-2z" />
        </g>
    </svg>
);

export const Remove = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path
                d="M456.051 2.504L2.503 456.053l53.445 53.445L509.497 55.95z"
                overflow="visible"
                fill="currentColor"
            />
            <path d="M55.948 2.504L2.503 55.949 456.05 509.5l53.446-53.446z" overflow="visible" fill="currentColor" />
        </g>
    </svg>
);

export const NotImplementedYet = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M256 512c-68.38 0-132.667-26.629-181.02-74.98C26.629 388.667 0 324.38 0 256S26.629 123.333 74.98 74.98C123.333 26.629 187.62 0 256 0s132.667 26.629 181.02 74.98C485.371 123.333 512 187.62 512 256s-26.629 132.667-74.98 181.02C388.667 485.371 324.38 512 256 512zm0-472C136.897 40 40 136.897 40 256s96.897 216 216 216 216-96.897 216-216S375.103 40 256 40zm93.737 260.188c-9.319-5.931-21.681-3.184-27.61 6.136-.247.387-25.137 38.737-67.127 38.737s-66.88-38.35-67.127-38.737c-5.93-9.319-18.291-12.066-27.61-6.136s-12.066 18.292-6.136 27.61c1.488 2.338 37.172 57.263 100.873 57.263s99.385-54.924 100.873-57.263c5.93-9.319 3.183-21.68-6.136-27.61zM168 165c13.807 0 25 11.193 25 25s-11.193 25-25 25-25-11.193-25-25 11.193-25 25-25zm150 25c0 13.807 11.193 25 25 25s25-11.193 25-25-11.193-25-25-25-25 11.193-25 25z"
            fill="currentColor"
        />
    </svg>
);

export const Triangle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="m84.125 188.612 252.164-171.93C373.509-8.695 424.253.905 449.63 38.125a81.566 81.566 0 0 1 14.174 45.949v343.86c0 45.048-36.518 81.566-81.566 81.566a81.568 81.568 0 0 1-45.95-14.174L84.126 323.396c-37.22-25.377-46.82-76.122-21.443-113.341a81.57 81.57 0 0 1 21.443-21.443z"
            fill="currentColor"
        />
    </svg>
);

export const Sort = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 14">
        <path d="M0 0h21v2H0zm0 6h12.833v2H0zm0 6h17.5v2H0z" fill="currentColor" />
    </svg>
);

export const RemoveBold = () => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M489.846 394.952L350.899 256.005l138.947-138.947c26.204-26.204 26.204-68.69 0-94.894-26.204-26.204-68.69-26.204-94.894 0L255.994 161.11 117.047 22.153c-26.215-26.204-68.702-26.204-94.894 0-26.204 26.214-26.204 68.701 0 94.894L161.11 255.994 22.153 394.94c-26.204 26.214-26.204 68.701 0 94.894 13.118 13.107 30.274 19.65 47.452 19.65 17.167 0 34.346-6.554 47.453-19.65l138.936-138.936 138.958 138.947c13.118 13.096 30.274 19.65 47.452 19.65 17.179 0 34.346-6.554 47.453-19.65 26.193-26.204 26.193-68.68-.011-94.894z"
            fill="currentColor"
        />
    </svg>
);

export const RemoveThin = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M509.5 32.372L479.628 2.5 256 226.128 32.372 2.5 2.5 32.372 226.128 256 2.5 479.628 32.372 509.5 256 285.872 479.628 509.5l29.872-29.872L285.872 256z"
            fill="currentColor"
        />
    </svg>
);

export const Plus = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
            d="M2.5 287.632h221.868V509.5h63.264V287.632H509.5v-63.264H287.632V2.5h-63.264v221.868H2.5z"
            fill="currentColor"
        />
    </svg>
);

export const Filter = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g transform="matrix(23.04546 0 0 23.04545 -9169.492 -192542.19)">
            <g stroke="none">
                <path d="M401.5 8357.5h1v7h-1zm0 10h1v7h-1zm7-2h1v9h-1zm0-8h1v5h-1z" fill="currentColor" />
                <path d="M412.5 8361.5v1h-7v-1zm7 8v1h-7v-1zm-14-2v1h-7v-1zm10-10h1v9h-1z" fill="currentColor" />
                <path d="M415.5 8369.5h1v5h-1z" fill="currentColor" />
            </g>
        </g>
    </svg>
);

export const Phone = () => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M73.698 2.5c-41.49-.077-74.72 36.314-70.901 77.635a25.353 25.353 0 0 0 0 .346c7.961 74.689 33.436 146.425 74.317 209.435.02.03.03.069.05.1.012.018.037.03.05.049a472.057 472.057 0 0 0 145.118 144.871c.013.009.037-.008.05 0a480.098 480.098 0 0 0 209.039 74.219 25.353 25.353 0 0 0 .445.05c41.33 3.686 77.661-29.557 77.635-71.05v-68.673a25.353 25.353 0 0 0-6.14-16.438c-6.844-27.567-25.942-51.047-55.156-55.156a25.353 25.353 0 0 0-.198 0c-20.099-2.644-39.869-7.59-58.87-14.656h-.098c-25.934-9.696-55.337-3.446-75.06 15.992a25.353 25.353 0 0 0-.1.1l-13.665 13.615c-40.9-26.304-75.362-60.7-101.746-101.549l13.566-13.566a25.353 25.353 0 0 0 .148-.099c19.508-19.704 25.784-49.157 15.993-75.11a269.681 269.681 0 0 1-14.656-58.72 25.353 25.353 0 0 0-.05-.248c-5.022-35.17-35.672-61.504-71.197-61.147H73.697zm-.1 50.7a25.353 25.353 0 0 0 .1 0h68.821a25.353 25.353 0 0 0 .248 0c10.492-.105 19.064 7.24 20.547 17.626a320.18 320.18 0 0 0 17.379 69.614 25.353 25.353 0 0 0 .05.1c2.826 7.492 1.055 15.741-4.605 21.487l-29.064 29.014a25.353 25.353 0 0 0-4.11 30.5 392.043 392.043 0 0 0 147.15 146.901 25.353 25.353 0 0 0 30.45-4.11l29.063-29.013c5.763-5.651 14.127-7.444 21.686-4.605a25.353 25.353 0 0 0 .05 0 320.538 320.538 0 0 0 69.811 17.379c10.54 1.482 17.902 10.215 17.627 20.745a25.353 25.353 0 0 0 0 .644v68.722c.008 12.449-9.947 21.488-22.33 20.449-66.505-7.27-130.39-29.9-186.56-66.247a25.353 25.353 0 0 0-.199-.149c-52.27-33.146-96.627-77.363-129.87-129.572a25.353 25.353 0 0 0-.098-.198C83.194 206.199 60.452 142.06 53.299 75.332 52.257 63.048 61.24 53.168 73.6 53.2z"
            fill="currentColor"
        />
    </svg>
);

export const Youtube = () => (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M411.826 77.913H111.304C44.522 77.913 0 122.435 0 178.087v155.826c0 55.652 44.522 100.174 111.304 100.174h300.522c55.652 0 100.174-44.522 100.174-100.174V178.087c0-55.652-44.522-100.174-100.174-100.174ZM356.174 256l-144.696 66.783V178.087z"
            fill="currentColor"
        />
    </svg>
);

export const Instagram = () => (
    <svg
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        version="1.1"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <defs>
            <radialGradient id="instagram-gradient" r="150%" cx="30%" cy="107%">
                <stop stopColor="#fdf497" offset="0" />
                <stop stopColor="#fd5949" offset="0.3" />
                <stop stopColor="#d6249f" offset="0.6" />
                <stop stopColor="#285AEB" offset="0.8" />
            </radialGradient>
        </defs>
        <g strokeWidth="46.546" stroke="url(#instagram-gradient)">
            <rect x="23.273" y="23.273" width="465.45" height="465.45" rx="116.36" ry="116.36" />
            <path d="m349.09 241.34a93.091 93.091 0 1 1-78.429-78.429 93.091 93.091 0 0 1 78.429 78.429z" />
            <line x1="384" x2="384" y1="128" y2="128" stroke="#3457E6" />
        </g>
    </svg>
);

export const MapMarker = () => (
    <svg viewBox="0 0 30 38" xmlns="http://www.w3.org/2000/svg">
        <path d="M30,15A15,15,0,1,0,10.089,29.161L15,38l4.911-8.839A14.994,14.994,0,0,0,30,15Z" fill="currentColor" />
    </svg>
);

export const Checkmark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="circle" cx="26" cy="26" r="25" fill="none" />
        <path className="check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
);

export const Warning = () => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.158 3.475c.37-.633 1.305-.633 1.684 0l9.029 15.109c.37.632-.098 1.416-.848 1.416H2.977c-.75 0-1.218-.784-.848-1.416ZM13 15h-2v2h2v-2Zm-1-6c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1Z"
            fill="currentColor"
        />
    </svg>
);

export const Spinner = () => (
    <svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path opacity=".25" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4" />
        <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 16 16"
                to="360 16 16"
                dur="0.8s"
                repeatCount="indefinite"
            />
        </path>
    </svg>
);

export const Hide = () => (
    <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
        <line x1="1" x2="23" y1="1" y2="23" />
    </svg>
);

export const Show = () => (
    <svg
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
        <circle cx="12" cy="12" r="3" />
    </svg>
);

export const LinkedIn = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
        <path
            fill="#0288D1"
            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
        />
        <path
            fill="#FFF"
            d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
        />
    </svg>
);

export const Facebook = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
        <path fill="#039be5" d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z" />
        <path
            fill="#fff"
            d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
        />
    </svg>
);

export const Variants = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0L8.72727 5.45455H3.27273L6 0Z" fill="#3E3D40" />
        <rect y="6.54547" width="5.45455" height="5.45455" fill="#3E3D40" />
        <circle cx="9.27273" cy="9.27274" r="2.72727" fill="#3E3D40" />
    </svg>
);
