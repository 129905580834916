import { OverlayStyled, OverlayWrapperStyled } from './Overlay.style';
import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

type OverlayProps = {
    isActive: boolean;
    onCloseHandler: () => void;
    className?: string;
};

const TEST_IDENTIFIER = 'layout-overlay';

export const Overlay: FC<OverlayProps> = ({ isActive, onCloseHandler, className }) => (
    <OverlayWrapperStyled data-testid={TEST_IDENTIFIER}>
        <CSSTransition in={isActive} timeout={500} classNames="overlay" unmountOnExit>
            <OverlayStyled onClick={onCloseHandler} className={className} />
        </CSSTransition>
    </OverlayWrapperStyled>
);
