import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

export const FooterCopyrightStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 10px;

        @media ${theme.mediaQueries.queryVl} {
            border-top: 1px solid ${theme.color.greyVeryLight};
            flex-direction: row;
            justify-content: space-between;
            height: 74px;
            margin-bottom: 0;
        }
    `,
);

export const FooterCopyrightTextStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        height: 100%;

        color: ${theme.color.greyLight};
        font-size: 13px;
    `,
);

export const FooterCopyrightLogoStyled = styled.a`
    display: flex;
    width: 77px;
    margin-left: 7px;
`;

export const FooterCopyrightShopLogoStyled = styled.a(
    ({ theme }) => css`
        display: none;

        @media ${theme.mediaQueries.queryVl} {
            margin-right: 40px;
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;

            &:before {
                content: '';
                height: 5px;
                border-radius: ${theme.radius.medium} ${theme.radius.medium} 0 0;
                background: ${theme.color.primary};
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }
        }
    `,
);
