import { FormLineStyled } from './FormLine.style';
import { FormLinePropType } from './propTypes';
import { FC, HTMLAttributes } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'style'>;

type FormLineProps = NativeProps & FormLinePropType;

export const FormLine: FC<FormLineProps> = ({ bottomGap, children, style, ...columnProps }) => {
    return (
        <FormLineStyled style={style} {...columnProps} bottomGap={bottomGap}>
            {children}
        </FormLineStyled>
    );
};
