import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

export const HamburgerMenuStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        padding: 14px 10px;
        width: fit-content;
        height: 40px;

        cursor: pointer;
        background-color: ${theme.color.secondary};
        border-radius: ${theme.radius.medium};
    `,
);

export const HamburgerMenuTextStyled = styled.span(
    ({ theme }) => css`
        margin-left: 4px;

        color: ${theme.color.white};
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
    `,
);

export const HamburgerMenuImageStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;

        i {
            color: ${theme.color.white};
        }
    `,
);
