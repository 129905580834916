import { LogoWrapper } from './Logo.style';
import { LogoMetadata } from 'components/Basic/Head/LogoMetadata/LogoMetadata';
import { Link } from 'components/Basic/Link/Link';
import Image from 'next/image';
import { FC } from 'react';

const TEST_IDENTIFIER = 'layout-header-logo';

export const Logo: FC = () => (
    <>
        <LogoMetadata />
        <Link href="/">
            <LogoWrapper>
                <Image src="/images/logo.svg" data-testid={TEST_IDENTIFIER} layout="fill" />
            </LogoWrapper>
        </Link>
    </>
);
