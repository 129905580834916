import { Button } from 'components/Forms/Button/Button';
import { getInternationalizedStaticUrls } from 'helpers/localization/getInternationalizedStaticUrls';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import NextLink from 'next/link';
import { FC } from 'react';
import { useShopsysSelector } from 'redux/main';

const TEST_IDENTIFIER = 'layout-footer-footerboxinfo';

export const FooterBoxInfo: FC = () => {
    const t = useTypedTranslationFunction();
    const { url } = useShopsysSelector((state) => state.domain);
    const [storesUrl] = getInternationalizedStaticUrls(['/stores'], url);

    return (
        <div
            className="relative mb-5 flex flex-col items-start gap-5 rounded-md bg-secondary p-10 lg:mb-14 lg:flex-row lg:items-center lg:px-14 lg:py-5"
            data-testid={TEST_IDENTIFIER}
        >
            <div className="w-fit text-2xl font-bold text-white">
                {t('You can find our branches all over the Czech Republic')}
            </div>
            <div className="text-sm text-greyVeryLight">{t('Open from 7 a.m. on weekdays.')}</div>
            <NextLink href={storesUrl} passHref>
                <Button className="lg:ml-auto" variant="primary">
                    {t('Stores')}
                </Button>
            </NextLink>
        </div>
    );
};
