import { Loader } from './Loader';
import { LoadingOverlayStyled } from './LoaderWithOverlay.style';

export const LoaderWithOverlay: FC = ({ className }) => {
    return (
        <LoadingOverlayStyled>
            <Loader className={className} />
        </LoadingOverlayStyled>
    );
};
