import { mediaQueries } from './mediaQueries';
import initialStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
    color: {
        base: '#0d0d0d',
        primary: '#e95b10',
        primaryDarker: '#d95017',
        secondary: '#3e3d40',
        primaryLight: '#a3abff',
        white: '#fefefe',
        whitesmoke: '#e8e8ea',
        black: '#000',
        orange: '#ecb200',
        orangeLight: '#fff0c4',
        border: '#c4c9ff',
        red: '#ec5353',
        redLight: '#f2a2a2',
        green: '#00ecb1',
        greenLight: '#81f7da',
        greenVeryLight: '#e7fce6',
        greenDark: '#22b92a',
        grey: '#555764',
        greyLight: '#9e9e9f',
        greyVeryLight: '#f7f7f7',
        greyDark: '#414353',
        greyLighter: '#e2e3eb',
        blueLight: '#f2f2ff',
        blue: '#d9d9ff',
        creamWhite: '#fefefe',
        inStock: '#01946f',
    },

    fontSize: {
        bigger: '18px',
        default: '16px',
        small: '14px',
        extraSmall: '12px',
    },

    fontFamily: {
        base: 'Inter, sans-serif',
    },

    lineHeight: {
        default: 1.3,
    },

    layout: {
        width: '1240px',
        padding: '20px',
    },

    radius: {
        biggest: '13px',
        big: '11px',
        medium: '6px',
        small: '3px',
    },

    zIndex: {
        hidden: -1000,
        above: 1,
        menu: 1010,
        aboveMenu: 1020,
        overlay: 1030,
        mobileMenu: 1040,
        aboveMobileMenu: 1050,
        cart: 6000,
        aboveOverlay: 10001,
        maximum: 10100,
    },

    boxShadow: {
        green: '0 0 0 4px rgba(0, 236, 177, 0.12)',
    },

    button: {
        size: {
            default: {
                height: '48px',
                lineHeight: '27px',
                paddingVertical: '10px',
                paddingHorizontal: '32px',
                fontSize: () => theme.fontSize.default,
            },
            small: {
                height: '30px',
                lineHeight: '23px',
                paddingVertical: '3px',
                paddingHorizontal: '17px',
                fontSize: () => theme.fontSize.small,
            },
        },
        variant: {
            default: {
                color: () => theme.color.white,
                background: () => theme.color.primary,
                colorHover: () => theme.color.white,
                backgroundHover: () => theme.color.primaryDarker,
            },
            primary: {
                color: () => theme.color.white,
                background: () => theme.color.primary,
                colorHover: () => theme.color.black,
                backgroundHover: () => theme.color.white,
            },
            secondary: {
                color: () => theme.color.white,
                background: () => theme.color.secondary,
                colorHover: () => theme.color.black,
                backgroundHover: () => theme.color.white,
            },
        },
        borderRadius: {
            default: () => theme.radius.medium,
            big: () => theme.radius.big,
        },
    },

    transition: '0.2s cubic-bezier(.8, .20, .48, 1.0)',
    transitionEffect: 'cubic-bezier(.8, .20, .48, 1.0)',

    mediaQueries: { ...mediaQueries },
} as const;

export type Theme = typeof theme;

export const styled = initialStyled as ThemedStyledInterface<Theme>;
