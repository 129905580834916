import { Icon } from 'components/Basic/Icon/Icon';
import { FC, MouseEventHandler } from 'react';

type RemoveCartItemButtonProps = {
    onItemRemove: MouseEventHandler<HTMLButtonElement>;
};

const TEST_IDENTIFIER = 'pages-cart-removecartitembutton';

export const RemoveCartItemButton: FC<RemoveCartItemButtonProps> = ({ onItemRemove }) => (
    <button
        className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-full border-none bg-white p-0 outline-none transition hover:bg-blueLight"
        onClick={onItemRemove}
        data-testid={TEST_IDENTIFIER}
    >
        <Icon iconType="icon" icon="Close" className="mx-auto w-[10px] basis-[10px]" />
    </button>
);
