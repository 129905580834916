import { Overlay } from '../Overlay/Overlay';
import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

const localVariables = {
    headerItemGapSmall: '20px',
    headerItemGap: '32px',
    headerMobileButtonSize: '40px',
} as const;

export const HeaderStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 8px 0 11px;

        @media ${theme.mediaQueries.queryLg} {
            padding: 15px 0 11px;
        }

        @media ${theme.mediaQueries.queryVl} {
            padding: 23px 0 16px;
        }
    `,
);

export const HeaderLogoStyled = styled.div(
    ({ theme }) => css`
        order: 1;
        flex: 1;
        display: flex;
        margin-right: auto;

        @media ${theme.mediaQueries.queryLg} {
            align-self: flex-end;
        }

        @media ${theme.mediaQueries.queryVl} {
            flex: none;
            margin-right: ${localVariables.headerItemGapSmall};
        }

        @media ${theme.mediaQueries.queryXl} {
            margin-right: ${localVariables.headerItemGap};
        }
    `,
);

export const HeaderMiddleStyled = styled.div(
    ({ theme }) => css`
        width: 100%;
        order: 6;
        margin-top: 11px;

        @media ${theme.mediaQueries.queryLg} {
            margin-top: 20px;
        }

        @media ${theme.mediaQueries.queryVl} {
            order: 2;
            max-width: 550px;
            flex: 1;
            margin-top: 0;
            margin-left: auto;
            margin-right: 70px;
        }
    `,
);

export const HeaderLinksStyled = styled.div(
    ({ theme }) => css`
        order: 2;
        display: flex;

        @media ${theme.mediaQueries.queryLg} {
            margin-right: ${localVariables.headerItemGapSmall};
            margin-left: auto;
        }

        @media ${theme.mediaQueries.queryVl} {
            order: 3;
            margin-left: 0;
        }

        @media ${theme.mediaQueries.queryXl} {
            margin-right: ${localVariables.headerItemGap};
        }
    `,
);

export const HeaderCartStyled = styled.div(
    ({ theme }) => css`
        order: 3;
        position: relative;
        display: flex;

        @media ${theme.mediaQueries.queryVl} {
            order: 4;
        }
    `,
);

export const HeaderMenuButtonStyled = styled.div(
    ({ theme }) => css`
        display: flex;
        order: 5;
        align-items: center;
        justify-content: center;
        width: auto;
        height: ${localVariables.headerMobileButtonSize};
        margin-left: 16px;

        outline: 0;
        cursor: pointer;
        font-size: 18px;

        @media ${theme.mediaQueries.queryVl} {
            display: none;
        }
    `,
);

export const HeaderBlurredOverlayStyled = styled(Overlay)`
    backdrop-filter: blur(14px);
`;
