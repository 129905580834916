import { MouseEventHandler } from 'react';
import { twJoin } from 'tailwind-merge';

type OverlayProps = {
    isActive: boolean;
    isHiddenOnDesktop?: boolean;
    onClick?: MouseEventHandler;
};

const TEST_IDENTIFIER = 'basic-overlay';

export const Overlay: FC<OverlayProps> = ({ onClick, children, isHiddenOnDesktop, isActive }) => (
    <div
        className={twJoin(
            'fixed inset-0 z-overlay flex cursor-pointer items-center justify-center bg-black bg-opacity-60 transition-opacity',
            isHiddenOnDesktop && 'vl:hidden',
            isActive ? 'opacity-100' : 'pointer-events-none opacity-0',
        )}
        onClick={onClick}
        data-testid={TEST_IDENTIFIER}
    >
        {children}
    </div>
);
