import { Autocomplete } from './Autocomplete/Autocomplete';
import {
    AutocompleteSearchInnerStyled,
    AutocompleteSearchInStyled,
    AutocompleteSearchRemoveButtonStyled,
    AutocompleteSearchStyled,
} from './AutocompleteSearch.style';
import { Icon } from 'components/Basic/Icon/Icon';
import { SearchInput } from 'components/Forms/TextInput/SearchInput';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { MINIMAL_SEARCH_QUERY_LENGTH, useAutocompleteSearch } from 'connectors/search/AutocompleteSearch';
import { getInternationalizedStaticUrls } from 'helpers/localization/getInternationalizedStaticUrls';
import { useGtmSearchResultView } from 'hooks/gtm/useGtmSearchResultView';
import { useDebounce } from 'hooks/helpers/useDebounce';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useEffectOnce } from 'hooks/ui/useEffectOnce';
import { useGetWindowSize } from 'hooks/ui/useGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/useResizeWidthEffect';
import { useRouter } from 'next/router';
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useShopsysSelector } from 'redux/main';

const TEST_IDENTIFIER = 'layout-header-search-autocomplete-input';

export const AutocompleteSearch: FC = () => {
    const router = useRouter();
    const [autocompleteSearchQueryValue, setAutocompleteSearchQueryValue] = useState('');
    const debouncedAutocompleteSearchQuery = useDebounce(autocompleteSearchQueryValue, 200);
    const [hasAutocompleteSearchFocus, setAutocompleteSearchFocus] = useState(false);
    const [autocompleteSearchApiResults, areSearchResultsLoading] = useAutocompleteSearch(
        debouncedAutocompleteSearchQuery,
    );
    const autocompleteSearchInRef = useRef<HTMLDivElement>(null);
    const domainUrl = useShopsysSelector((state) => state.domain.url);
    const [searchUrl] = getInternationalizedStaticUrls(['/search'], domainUrl);
    const t = useTypedTranslationFunction();
    const [isDesktop, setIsDesktop] = useState(false);
    const { width } = useGetWindowSize();

    const autocompleteSearchResults = useMemo(() => {
        if (autocompleteSearchQueryValue.length < MINIMAL_SEARCH_QUERY_LENGTH) {
            return undefined;
        }

        return autocompleteSearchApiResults;
    }, [autocompleteSearchApiResults, autocompleteSearchQueryValue]);

    useGtmSearchResultView(autocompleteSearchApiResults, autocompleteSearchQueryValue);

    useEffectOnce(() => {
        const onDocumentClickHandler: EventListener = (event) => {
            if (autocompleteSearchInRef.current === null || !(event.target instanceof HTMLElement)) {
                setAutocompleteSearchFocus(false);
                return;
            }

            if (autocompleteSearchInRef.current.contains(event.target)) {
                setAutocompleteSearchFocus(true);
            } else {
                setAutocompleteSearchFocus(false);
            }
        };

        document.addEventListener('click', onDocumentClickHandler);

        return () => document.removeEventListener('click', onDocumentClickHandler);
    });

    useResizeWidthEffect(
        width,
        desktopFirstSizes.tablet,
        () => setIsDesktop(true),
        () => setIsDesktop(false),
    );

    const onAutocompleteSearchHandler = useCallback(() => {
        router.push({ pathname: searchUrl, query: { q: autocompleteSearchQueryValue } });
    }, [router, autocompleteSearchQueryValue, searchUrl]);

    const [isAutocompleteActive, setIsAutocompleteActive] = useState(false);

    const onCloseAutocomplete = useCallback(() => {
        setAutocompleteSearchQueryValue('');
        setIsAutocompleteActive(false);
    }, []);

    const onChangeAutocompleteSearchQueryValueHandler: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
        setAutocompleteSearchQueryValue(event.currentTarget.value);
    }, []);

    useEffect(() => {
        if (!isAutocompleteActive) {
            setIsAutocompleteActive(
                autocompleteSearchQueryValue.length >= MINIMAL_SEARCH_QUERY_LENGTH - 1 &&
                    hasAutocompleteSearchFocus &&
                    autocompleteSearchResults !== undefined,
            );
        }
    }, [
        autocompleteSearchQueryValue.length,
        autocompleteSearchResults,
        hasAutocompleteSearchFocus,
        isAutocompleteActive,
    ]);

    return (
        <>
            <AutocompleteSearchStyled>
                <AutocompleteSearchInStyled ref={autocompleteSearchInRef}>
                    {(isDesktop || !isAutocompleteActive) && (
                        <AutocompleteSearchInnerStyled isActive={hasAutocompleteSearchFocus}>
                            <SearchInput
                                label={t("Type what you're looking for")}
                                testIdentifier={TEST_IDENTIFIER}
                                onEnterPressCallback={onAutocompleteSearchHandler}
                                value={autocompleteSearchQueryValue}
                                onChange={onChangeAutocompleteSearchQueryValueHandler}
                                isLoading={areSearchResultsLoading}
                            />
                            {isAutocompleteActive && isDesktop && (
                                <AutocompleteSearchRemoveButtonStyled onClick={onCloseAutocomplete}>
                                    <Icon iconType="icon" icon="Close" />
                                </AutocompleteSearchRemoveButtonStyled>
                            )}
                        </AutocompleteSearchInnerStyled>
                    )}
                    <Autocomplete
                        autocompleteSearchResults={autocompleteSearchResults}
                        isAutocompleteActive={isAutocompleteActive}
                        autocompleteSearchQueryValue={autocompleteSearchQueryValue}
                        onAutocompleteSearchHandler={onAutocompleteSearchHandler}
                        areSearchResultsLoading={areSearchResultsLoading}
                        onChangeAutocompleteSearchQueryValueHandler={onChangeAutocompleteSearchQueryValueHandler}
                        onCloseAutocomplete={onCloseAutocomplete}
                    />
                </AutocompleteSearchInStyled>
            </AutocompleteSearchStyled>
        </>
    );
};
