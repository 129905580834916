import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

export const LogoWrapper = styled.div(
    ({ theme }) => css`
        width: 72px;
        height: 33px;
        position: relative;

        @media ${theme.mediaQueries.queryLg} {
            width: 125px;
            height: 58px;
        }
    `,
);
