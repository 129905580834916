import { AutocompleteSearch } from './AutocompleteSearch/AutocompleteSearch';
import { Cart } from './Cart/Cart';
import { DropdownMenu } from './DropdownMenu/DropdownMenu';
import { HamburgerMenu } from './HamburgerMenu/HamburgerMenu';
import {
    HeaderBlurredOverlayStyled,
    HeaderCartStyled,
    HeaderLinksStyled,
    HeaderLogoStyled,
    HeaderMenuButtonStyled,
    HeaderMiddleStyled,
    HeaderStyled,
} from './Header.style';
import { Logo } from './Logo/Logo';
import { MenuIconic } from './MenuIconic/MenuIconic';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

type HeaderProps = {
    simpleHeader?: boolean;
};

const TEST_IDENTIFIER = 'layout-header';

export const Header: FC<HeaderProps> = ({ simpleHeader }) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const onMenuToggleHandler = useCallback(() => {
        setIsMenuOpened((prev) => !prev);
    }, []);

    useEffect(() => {
        if (dropdownRef.current) {
            if (isMenuOpened) {
                disableBodyScroll(dropdownRef.current);
            } else {
                enableBodyScroll(dropdownRef.current);
            }
        }

        return () => {
            clearAllBodyScrollLocks();
        };
    }, [isMenuOpened]);

    return (
        <HeaderStyled data-testid={TEST_IDENTIFIER}>
            <HeaderLogoStyled>
                <Logo />
            </HeaderLogoStyled>
            {!simpleHeader && (
                <>
                    <HeaderMiddleStyled>
                        <AutocompleteSearch />
                    </HeaderMiddleStyled>
                    <HeaderLinksStyled>
                        <MenuIconic />
                    </HeaderLinksStyled>
                    <HeaderCartStyled>
                        <Cart />
                    </HeaderCartStyled>
                    <HeaderMenuButtonStyled>
                        <HamburgerMenu onMenuToggleHandler={onMenuToggleHandler} isMenuOpened={false} />
                        <DropdownMenu
                            onMenuToggleHandler={onMenuToggleHandler}
                            isMenuOpened={isMenuOpened}
                            ref={dropdownRef}
                        />
                    </HeaderMenuButtonStyled>
                    <HeaderBlurredOverlayStyled isActive={isMenuOpened} onCloseHandler={onMenuToggleHandler} />
                </>
            )}
        </HeaderStyled>
    );
};
