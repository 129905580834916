import { ButtonDefaultPropType } from 'components/Forms/Button/propTypes';
import NextLink from 'next/link';
import { AnchorHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import { UrlObject } from 'url';
import { twMergeCustom } from 'utils/twMerge';

type LinkProps = Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'href'> &
    ButtonDefaultPropType & {
        linkType?: 'external';
        isButton?: boolean;
        href: string | UrlObject;
        children?: ReactNode | string;
    };

export const Link: FC<LinkProps> = ({
    children,
    href,
    isButton,
    linkType,
    size = 'default',
    variant = 'default',
    className,
    ...restProps
}) => {
    const testIdentifier =
        'basic-link' + (linkType !== undefined ? '-' + linkType : '') + (isButton === true ? '-button' : '');

    if (linkType === 'external') {
        if (isButton === true) {
            return (
                <a
                    className={twMergeCustom(
                        size === 'default' &&
                            'min-h-[48px] px-8 py-[10px] text-base leading-7 [&>i:first-child]:mr-3 [&>i:last-child:not(:first-child)]:ml-3',
                        size === 'small' &&
                            'min-h-[30px] px-4 py-1 text-sm leading-6 [&>i:first-child]:mr-1 [&>i:last-child:not(:first-child)]:ml-1',
                        variant === 'default' &&
                            'bg-primary text-white hover:bg-primaryDarker hover:text-white [&>i]:hover:text-white',
                        variant === 'primary' &&
                            'bg-primary text-white hover:bg-white hover:text-black [&>i]:hover:text-black',
                        variant === 'secondary' &&
                            'bg-secondary text-white hover:bg-white hover:text-black [&>i]:hover:text-black',
                        'flex cursor-pointer items-center justify-center rounded-md border-none text-center leading-5 no-underline outline-0 transition hover:no-underline',
                        className,
                    )}
                    data-testid={testIdentifier}
                    href={href as string}
                    {...restProps}
                >
                    {children}
                </a>
            );
        }

        return (
            <a
                className={twMergeCustom('ui-link', className)}
                data-testid={testIdentifier}
                href={href as string}
                {...restProps}
            >
                {children}
            </a>
        );
    }

    if (isButton === true) {
        return (
            <NextLink href={href} passHref>
                <a
                    className={twMergeCustom(
                        size === 'default' &&
                            'min-h-[48px] px-8 py-[10px] text-base leading-7 [&>i:first-child]:mr-3 [&>i:last-child:not(:first-child)]:ml-3',
                        size === 'small' &&
                            'min-h-[30px] px-4 py-1 text-sm leading-6 [&>i:first-child]:mr-1 [&>i:last-child:not(:first-child)]:ml-1',
                        variant === 'default' &&
                            'bg-primary text-white hover:bg-primaryDarker hover:text-white [&>i]:hover:text-white',
                        variant === 'primary' &&
                            'bg-primary text-white hover:bg-white hover:text-black [&>i]:hover:text-black',
                        variant === 'secondary' &&
                            'bg-secondary text-white hover:bg-white hover:text-black [&>i]:hover:text-black',
                        'flex cursor-pointer items-center justify-center rounded-md border-none text-center leading-5 no-underline outline-0 transition hover:no-underline',
                        className,
                    )}
                    data-testid={testIdentifier}
                    {...restProps}
                >
                    {children}
                </a>
            </NextLink>
        );
    }

    return (
        <NextLink href={href} passHref>
            <a className={twMergeCustom('ui-link', className)} data-testid={testIdentifier} {...restProps}>
                {children}
            </a>
        </NextLink>
    );
};
