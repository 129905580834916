import { DropdownItem } from 'components/Layout/Header/DropdownMenu/Item/DropdownItem';
import { FC } from 'react';
import { NavigationItem as NavigationItemType } from 'types/navigation';

type PrimaryListProps = {
    navigationItems: NavigationItemType[];
};

export const PrimaryList: FC<PrimaryListProps> = ({ navigationItems }) => (
    <>
        {navigationItems.map((navigationItem, index) => (
            <DropdownItem key={index} navigationItem={navigationItem} index={index} goToMenu="secondary" />
        ))}
    </>
);
