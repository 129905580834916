export const SEARCH_QUERY_PARAMETER_NAME = 'q' as const;
export const PAGE_QUERY_PARAMETER_NAME = 'page' as const;
export const FILTER_QUERY_PARAMETER_NAME = 'filter' as const;
export const SORT_QUERY_PARAMETER_NAME = 'sort' as const;
export const INTERNAL_QUERY_PARAMETERS = [
    SEARCH_QUERY_PARAMETER_NAME,
    PAGE_QUERY_PARAMETER_NAME,
    FILTER_QUERY_PARAMETER_NAME,
    SORT_QUERY_PARAMETER_NAME,
];
