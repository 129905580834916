import { Icon } from 'components/Basic/Icon/Icon';
import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

export const DropdownSlideLeftStyled = styled.span(
    ({ theme }) => css`
        align-items: center;
        cursor: pointer;
        display: inline-flex;
        position: relative;
        text-transform: uppercase;

        color: ${theme.color.base};
        font-size: 12px;
        font-weight: 500;
    `,
);

export const DropdownSlideLeftIconStyled = styled(Icon)`
    transform: rotate(90deg);
    margin-right: 9px;
`;
