import { Heading } from 'components/Basic/Heading/Heading';
import { Icon } from 'components/Basic/Icon/Icon';
import { SimpleNotBlogArticleFragmentApi } from 'graphql/generated';
import Link from 'next/link';
import { FC, useState } from 'react';
import { twJoin } from 'tailwind-merge';

type FooterMenuItemProps = {
    title: string;
    items: SimpleNotBlogArticleFragmentApi[];
};

const TEST_IDENTIFIER = 'layout-footer-footermenuitem';

export const FooterMenuItem: FC<FooterMenuItemProps> = ({ items, title }) => {
    const [isContentVisible, setIsContentVisible] = useState(false);

    return (
        <div
            className="border-t-2 border-greyLighter px-5 last:border-b-2 vl:flex-1 vl:border-none vl:p-0"
            data-testid={TEST_IDENTIFIER}
        >
            <Heading
                className="mb-0 flex cursor-pointer items-center justify-between py-4 text-base font-bold uppercase leading-7 text-primary vl:pointer-events-none vl:mb-4 vl:items-start vl:p-0 vl:leading-5"
                onClick={() => setIsContentVisible((prev) => !prev)}
                type="h4"
            >
                {title}
                <Icon
                    className={twJoin(
                        'h-4 w-4 text-greyLight transition vl:hidden',
                        isContentVisible ? 'rotate-180' : 'rotate-0',
                    )}
                    iconType="icon"
                    icon="Arrow"
                />
            </Heading>
            <ul
                className={twJoin(
                    'pb-5 vl:pb-0',
                    !isContentVisible && 'h-0 overflow-hidden !p-0 vl:h-auto vl:overflow-auto',
                )}
            >
                {items.map((item) => (
                    <li className="mb-1 last:mb-0 vl:mb-0" key={item.name}>
                        <Link href={item.__typename === 'ArticleSite' ? item.slug : item.url} passHref>
                            <a
                                className="block py-1 text-sm text-secondary no-underline hover:underline hover:brightness-90"
                                target={item.__typename === 'ArticleLink' ? '_blank' : undefined}
                                rel={item.__typename === 'ArticleLink' ? 'noreferrer' : undefined}
                            >
                                {item.name}
                            </a>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};
