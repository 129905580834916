import { getFirstImage } from 'connectors/image/Image';
import { mapPayment } from 'connectors/payments/Payment';
import { mapPriceData } from 'connectors/price/Prices';
import { mapPickupPlacesApiData } from 'connectors/transports/pickupPlace/PickupPlace';
import { TransportWithAvailablePaymentsAndStoresFragmentApi, useTransportsQueryApi } from 'graphql/generated';
import { useMemo } from 'react';
import { useShopsysSelector } from 'redux/main';
import { TransportType } from 'types/transport';

export const useTransports = (cartUuid: string | null): TransportType[] => {
    const { currencyCode } = useShopsysSelector((state) => state.domain);
    const [result] = useTransportsQueryApi({ variables: { cartUuid }, requestPolicy: 'cache-and-network' });
    const transportsApiData = result.data?.transports;

    return useMemo(() => {
        if (transportsApiData !== undefined) {
            return mapTransports(transportsApiData, currencyCode);
        }
        return [];
    }, [currencyCode, transportsApiData]);
};

export const mapTransport = (
    apiData: TransportWithAvailablePaymentsAndStoresFragmentApi,
    currencyCode: string,
): TransportType => {
    return {
        ...apiData,
        description: apiData.description !== null ? apiData.description : '',
        instruction: apiData.instruction !== null ? apiData.instruction : '',
        image: getFirstImage(apiData.images),
        price: mapPriceData(apiData.price, currencyCode),
        payments: apiData.payments.map((payment) => mapPayment(payment, currencyCode)),
        stores: apiData.stores !== null ? mapPickupPlacesApiData(apiData.stores) : [],
    };
};

const mapTransports = (
    apiData: TransportWithAvailablePaymentsAndStoresFragmentApi[],
    currencyCode: string,
): TransportType[] => {
    const mappedTransports: TransportType[] = [];
    for (const transport of apiData) {
        const mappedTransport = mapTransport(transport, currencyCode);
        mappedTransports.push(mappedTransport);
    }

    return mappedTransports;
};
