import { FooterBoxInfo } from './FooterBoxInfo/FooterBoxInfo';
import { FooterContact } from './FooterContact/FooterContact';
import { FooterCopyright } from './FooterCopyright/FooterCopyright';
import { FooterMenu } from './FooterMenu/FooterMenu';
import { FC } from 'react';

type FooterProps = {
    simpleFooter?: boolean;
};

const FOOTER_TEST_IDENTIFIER = 'layout-footer';

export const Footer: FC<FooterProps> = ({ simpleFooter }) => {
    return (
        <div className="relative mt-auto" data-testid={FOOTER_TEST_IDENTIFIER}>
            <div className="flex flex-col pb-8 pt-5 vl:pb-0 vl:pt-7">
                {!simpleFooter && (
                    <>
                        <FooterBoxInfo />
                        <div className="mb-11 vl:mb-5 vl:flex vl:justify-between">
                            <FooterMenu />
                            <FooterContact />
                        </div>
                    </>
                )}
                <FooterCopyright />
            </div>
        </div>
    );
};
