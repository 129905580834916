import { ButtonHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';

type NativeButtonProps = ExtractNativePropsFromDefault<
    ButtonHTMLAttributes<HTMLButtonElement>,
    never,
    'type' | 'onClick' | 'style' | 'name'
>;

type Props = Omit<NativeButtonProps, 'type'> & {
    type?: 'button' | 'submit' | 'reset';
    isDisabled?: boolean;
    hasDisabledLook?: boolean;
    size?: 'small';
    variant?: 'primary' | 'secondary';
    isRounder?: boolean;
};

export const Button: FC<Props> = ({
    children,
    type = 'button',
    dataTestId,
    onClick,
    name,
    className,
    isDisabled: isDisabledDefault,
    hasDisabledLook,
    isRounder,
    style,
    size = 'default',
    variant = 'default',
}) => {
    const formProviderMethods = useFormContext();

    // formProviderMethods may be null probably when it is not used in FormProvider context - see https://github.com/react-hook-form/react-hook-form/discussions/3894
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isDisabled = isDisabledDefault || (type === 'submit' && formProviderMethods?.formState.isSubmitting);

    return (
        <button
            className={twMerge(
                size === 'default' &&
                    'min-h-[48px] px-8 py-[10px] text-base leading-7 [&>i:first-child]:mr-3 [&>i:last-child:not(:first-child)]:ml-3',
                size === 'small' &&
                    'min-h-[30px] px-4 py-1 text-sm leading-6 [&>i:first-child]:mr-1 [&>i:last-child:not(:first-child)]:ml-1',
                variant === 'default' &&
                    'bg-primary text-white hover:bg-primaryDarker hover:text-white [&>i]:hover:text-white',
                variant === 'primary' && 'bg-primary font-bold text-white hover:bg-primaryDarker',
                variant === 'secondary' &&
                    'bg-secondary text-white hover:bg-white hover:text-black [&>i]:hover:text-black',
                'flex cursor-pointer items-center justify-center rounded-md border-none text-center leading-5 no-underline outline-0 transition hover:no-underline',
                (isDisabled || hasDisabledLook) && 'cursor-no-drop opacity-50',
                isDisabled && 'pointer-events-none',
                isRounder ? 'rounded-xl' : 'rounded',
                className,
            )}
            style={style}
            type={type}
            data-testid={dataTestId}
            onClick={onClick}
            name={name}
        >
            {children}
        </button>
    );
};
