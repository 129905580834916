import { Heading } from 'components/Basic/Heading/Heading';
import { Icon } from 'components/Basic/Icon/Icon';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { FC } from 'react';
import { useShopsysSelector } from 'redux/main';

const TEST_IDENTIFIER = 'layout-footer-footercontact';

const footerContactSocialsItemTwClass =
    'flex aspect-square h-full w-24 items-center justify-center rounded-md bg-greyVeryLight hover:bg-greyLighter transition first:border-l-0';

const footerContactLangsItemTwClass =
    'flex items-center rounded-md bg-greyVeryLight p-3 no-underline hover:bg-greyLighter transition hover:no-underline';

export const FooterContact: FC = () => {
    const t = useTypedTranslationFunction();
    const { defaultLocale } = useShopsysSelector(({ domain }) => domain);
    const isDesktop = useMediaMin('vl');

    return (
        <div
            className="flex h-full flex-col items-center vl:w-1/4 vl:items-start vl:pl-5"
            data-testid={TEST_IDENTIFIER}
        >
            {isDesktop && (
                <>
                    <Heading className="mb-6 flex items-center justify-between p-0 uppercase text-primary" type="h4">
                        {t('Follow Us')}
                    </Heading>
                    <div className="maw-w-[400px] flex w-full gap-2 overflow-hidden rounded-xl">
                        <a
                            className={footerContactSocialsItemTwClass}
                            target="_blank"
                            href={
                                defaultLocale === 'cs'
                                    ? 'https://www.linkedin.com/company/jaf-holz-spol-s-r-o/'
                                    : 'https://www.linkedin.com/company/jaf-holz-slovakia/'
                            }
                            rel="noreferrer"
                        >
                            <Icon className="h-8 w-8 text-white" iconType="icon" icon="LinkedIn" />
                        </a>
                        <a
                            className={footerContactSocialsItemTwClass}
                            target="_blank"
                            href={
                                defaultLocale === 'cs'
                                    ? 'https://www.facebook.com/jafholzcz'
                                    : 'https://www.facebook.com/jafholzsk'
                            }
                            rel="noreferrer"
                        >
                            <Icon iconType="icon" icon="Facebook" className="h-8 w-8" alt={t('Facebook')} />
                        </a>
                        <a
                            className={footerContactSocialsItemTwClass}
                            target="_blank"
                            href={
                                defaultLocale === 'cs'
                                    ? 'https://www.instagram.com/jafholz_cz/'
                                    : 'https://www.instagram.com/jaf_holz_sk/'
                            }
                            rel="noreferrer"
                        >
                            <Icon className="h-8 w-8" iconType="icon" icon="Instagram" />
                        </a>
                    </div>
                </>
            )}
            <div className="flex-wor mt-8 flex flex-wrap gap-2 vl:ml-auto vl:mt-24">
                <a className={footerContactLangsItemTwClass} href={process.env.DOMAIN_HOSTNAME_1 ?? '/'}>
                    <Icon iconType="image" icon="cz" width={24} height={16} alt={t('Czechia')} />
                    <span className="ml-2 text-sm text-secondary">{t('Czechia')}</span>
                </a>
                <a className={footerContactLangsItemTwClass} href={process.env.DOMAIN_HOSTNAME_2 ?? '/'}>
                    <Icon iconType="image" icon="sk" width={24} height={16} alt={t('Slovakia')} />
                    <span className="ml-2 text-sm text-secondary">{t('Slovakia')}</span>
                </a>
            </div>
        </div>
    );
};
