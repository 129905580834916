import { Icon } from 'components/Basic/Icon/Icon';
import { styled } from 'components/Theme/main';
import { css } from 'styled-components';

export const DropdownSlideRightStyled = styled.span(
    ({ theme }) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;

        cursor: pointer;
        color: ${theme.color.base};

        img {
            transform: rotate(-90deg);
        }
    `,
);

export const DropdownSlideRightIconStyled = styled(Icon)`
    transform: rotate(-90deg);
    width: 17px;
    height: 17px;
`;
