import {
    FooterCopyrightLogoStyled,
    FooterCopyrightShopLogoStyled,
    FooterCopyrightStyled,
    FooterCopyrightTextStyled,
} from './FooterCopyright.style';
import { useTypedTranslationFunction } from 'hooks/typescript/useTypedTranslationFunction';
import Image from 'next/image';
import { FC } from 'react';

const TEST_IDENTIFIER = 'layout-footer-footercopyright';

export const FooterCopyright: FC = () => {
    const t = useTypedTranslationFunction();

    return (
        <FooterCopyrightStyled data-testid={TEST_IDENTIFIER}>
            <FooterCopyrightTextStyled>
                <FooterCopyrightShopLogoStyled href="/">
                    <Image src="/images/logo-black.svg" width={61} height={28} />
                </FooterCopyrightShopLogoStyled>
                {t('© 2023, JAF HOLZ s.r.o.')}
            </FooterCopyrightTextStyled>
            <FooterCopyrightTextStyled>
                {t('Professional customized E-shop by')}
                <FooterCopyrightLogoStyled href="https://www.shopsys.com" target="_blank">
                    <Image src="/images/logo-shopsys.svg" width={77} height={18} />
                </FooterCopyrightLogoStyled>
            </FooterCopyrightTextStyled>
        </FooterCopyrightStyled>
    );
};
